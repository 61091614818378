.header {
    padding: 5px;
    width: 100% - 10px;
    height: 64px;
    background-color: rgba(71, 82, 196, 1);
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}

.icon {
    position: absolute;
    left: 20px;
    display: flex;
    align-items: center;
    font-size: 26px;
}

.icon>p {
    margin-left: .6em;
}

.social {
    position: absolute;
    right: 20px;
}

.social>a>img {
    width: 40px;
}