body {
    margin: 0;
    background-color: rgb(29, 29, 29);
    color: white;
}

.content {
    margin-top: 100px;
    max-width: 600px;
    padding: 0 5px;
    margin-left: auto;
    margin-right: auto;
}

ol,
ul {
    padding-inline-start: 20px;
}

dd {
    margin-inline-start: -10px;
}